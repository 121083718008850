(function(html){
  const YEARS_IN_MS = 365 * 24 * 60 * 60 * 1000;
  const start = new Date('2008-04');
  const diffYears = (Date.now() - start) / YEARS_IN_MS;
  const treeWalker = document.createTreeWalker(document.body, NodeFilter.SHOW_TEXT, null, false);

  while(treeWalker.nextNode()) {
    const node = treeWalker.currentNode;
    if(node.nodeType === 3 && node.nodeValue.indexOf('{{years}}') !== -1 ){
      node.nodeValue = node.nodeValue.replace('{{years}}', Math.ceil(diffYears));
    }
  }

  // (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
  //   (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
  //     m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
  // })(window,document,'script','//www.google-analytics.com/analytics.js','ga');

  // const trackOutboundLink = function(event) {
  //   event.preventDefault();
  //   const url = this.href;
  //   const goToUrl = () => {
  //     document.location = url;
  //   };

  //   ga('send', 'event', 'outbound', 'click', url, {
  //     hitCallback: goToUrl
  //   });

  //   setTimeout(goToUrl, 500);
  // };

  // const addClickEvent = (element) => {
  //   element.addEventListener('click', trackOutboundLink, false);
  // };

  // document.querySelectorAll('a').forEach(addClickEvent);

  // ga('create', 'UA-4672599-3', 'auto');
  // ga('send', 'pageview');

  const link = document.createElement('link');
  link.rel='stylesheet';
  link.href='https://fonts.googleapis.com/css2?family=Oswald&display=swap';
  document.head.append(link);


  if(document.readyState === "complete"){
    html.classList.add('loaded');
  } else {
    window.addEventListener('load', () => html.classList.add('loaded'), false)
  }

})(document.documentElement);

